import MDinput from '@/components/MDinput';
import { validatorRequire, validatorRequireNumber } from '@/utils/validators';
import Select from '@/components/DSE/Select';
import Switch from '@/components/DSE/Switch';
import { VATRateOption } from '@/utils/constants';
import PickRec from '@/components/DSE/PickRec';
import NumericInput from '@/components/DSE/NumericInput';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    restaurantID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'common.restaurant',
        valueField: 'id',
        labelField: 'name',
        inputField: 'name',
        maxlength: 64,
        columns: [
          { field: 'name', label: 'common.restaurant' },
          { field: 'code', label: 'common.code' }
        ],
        entity: 'Restaurant',
        required: true,
        usePagination: true,
        hideInput: false,
        suggest: true
      },
      rules: [{ validator: validatorRequire }]
    },
    localProductCategoryID: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'localProduct.localProductCategory',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['localProductCategory/isLoading'],
        options: vue.$store.getters['localProductCategory/loadedItems']
      },
      dynamicProps: form => ({
        options: vue.$store.getters['localProductCategory/loadedItems'].filter(
          e => e.restaurantID === form.restaurantID
        ),
        visible: +form.restaurantID > 0
      }),
      valueOnFormChange: (form, oldvalue) => {
        return oldvalue;
      }
    },
    price: {
      type: MDinput,
      props: {
        type: 'currency',
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    vatRateOption: {
      type: Select,
      defaultValue: VATRateOption.Reduced,
      props: {
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('vatRate'),
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    ean: {
      type: MDinput,
      defaultValue: null,
      props: {
        maxlength: 13
      }
    },
    deposit: {
      type: MDinput,
      defaultValue: undefined,
      props: {
        caption: 'product.deposit',
        type: 'currency'
      }
    },
    position: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'common.positionInCashDesk'
      }
    },
    onlineYN: {
      type: Switch,
      defaultValue: true,
      props: {
        caption: 'localProduct.online',
        activeText: 'common.active',
        inactiveText: 'common.inactive'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
